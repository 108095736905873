<template>
    <BT-Dialog
        width="500"
        :block="!iconOnly"
        :buttonClass="buttonClass"
        label="Packages"
        :text="iconOnly ? null : 'Edit Packages'"
        :icon="iconOnly ? 'mdi-pencil' : 'mdi-cube-unfolded'"
        :getOnOpenAsync="getPackages"
        @ok="save"
        :small="small">
        <template slot-scope="{ item }">
            <v-list>
                <template v-for="(m, index) in item">
                    <v-list-item :key="index">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ m.measurementName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ m.measurementName | toMeasurementLine }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content>
                            <BT-Number-Edit
                                v-model="m.quantity"
                                label="Quantity" />
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </template>
    </BT-Dialog>
</template>

<script>
export default {
    name: 'BT-Packages-Dialog',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue')
    },
    data: function() {
        return { }
    },
    props: {
        buttonClass: {
            type: String,
            default: null
        },
        iconOnly: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        value: null,
    },
    methods: {
        async getPackages() {
            var measurements = await this.$BlitzIt.store.getAll('measurements');
            
            console.log('getting packages');
            
            var packagedItems = measurements.map(x => Object.assign({}, x, {
                code: null,
                height: null,
                id: null,
                length: null,
                measurementID: x.id,
                measurementStandard: x.measurementStandard,
                quantity: 0,
                volume: null,
                width: null
            }));

            if (this.isLengthyArray(this.value)) {
                this.value.forEach(v => {
                    var pkg = null;
                    if (v.measurementID != null) {
                        pkg = packagedItems.find(x => x.measurementID == v.measurementID);
                    }

                    if (pkg == null && v.measurementStandard != null) {
                        pkg = packagedItems.find(x => x.measurementStandard == v.measurementStandard);
                    }

                    if (pkg == null) {
                        //then add independently
                        packagedItems.push(v);
                    }
                    else {
                        //fill values
                        pkg.id = v.id;
                        pkg.quantity = v.quantity;
                        pkg.code = v.code;
                        pkg.height = v.height;
                        pkg.width = v.width;
                        pkg.length = v.length;
                        pkg.volume = v.volume;
                    }
                })
            }

            // packagedItems.forEach(m => {
            //     if (this.value != null) {
            //         var existingPackage = this.value.find(y => y.measurementID == m.measurementID || (y.measurementStandard != null && y.measurementStandard == m.measurementStandard));
            //         if (existingPackage != null) {
            //             m.id = existingPackage.id;
            //             m.quantity = existingPackage.quantity;
            //             m.code = existingPackage.code;
            //             m.height = existingPackage.height;
            //             m.width = existingPackage.width;
            //             m.length = existingPackage.length;
            //             m.volume = existingPackage.volume;
            //         }
            //     }
            // });

            return packagedItems;
        },
        save(res) {
            if (this.isLengthyArray(res)) {
                this.$emit('ok', res.filter(y => y.quantity != 0));
            }
            else {
                this.$emit('ok', res);
            }
        }
    }
}
</script>